import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import dropdownIcon from '../../../assets/icons/arrowIcon.svg';
import ServiceIcon from '../../../assets/icons/serviceIcon.svg';
import Input from '../Input';

interface Option {
  label: string;
  value: string;
  iconUrl?: string;
}

interface MultiSelectProps {
  id: string;
  options: Option[];
  label: string;
  placeholder?: string;
  onChange: (selectedItems: string[]) => void;
  selectedItems: string[];
  containerClassName?: string;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  id,
  options,
  label,
  placeholder = 'Select items',
  onChange,
  selectedItems = [],
  containerClassName = '',
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggleItem = (value: string) => {
    const updatedItems = selectedItems.includes(value)
      ? selectedItems.filter(item => item !== value)
      : [...selectedItems, value];
    onChange(updatedItems);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      setIsOpen(!isOpen);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={containerClassName}>
      <label
        htmlFor={id}
        className="mb-2 block font-airbnb text-[16px] font-medium text-gray-900"
      >
        {label}
        <span className="text-red-600">*</span>
      </label>
      <div className="relative">
        <div
          id={id}
          className={clsx(
            'flex cursor-pointer items-center justify-between rounded-md border border-gray-300 p-3 shadow-md ',
            { 'focus:outline-none': isOpen },
          )}
          onClick={() => setIsOpen(!isOpen)}
          onKeyDown={handleKeyDown}
          aria-expanded={isOpen}
          tabIndex={0}
          role="button"
          aria-haspopup="listbox"
        >
          {selectedItems.length === 0 ? (
            <span className="text-[14px] text-gray-600">{placeholder}</span>
          ) : (
            <div className="flex flex-wrap">
              {selectedItems.map(item => {
                const selectedLabel =
                  options.find(opt => opt.value === item)?.label || item;
                return (
                  <span
                    key={item}
                    className="mr-2 flex items-center rounded-full py-1 font-airbnb text-[14px] font-normal text-gray-700 "
                  >
                    {selectedLabel}{' '}
                  </span>
                );
              })}
            </div>
          )}
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-5">
            <img
              src={dropdownIcon}
              alt={t('MultiSelect.dropdown_icon')}
              width={10}
              height={10}
            />
          </div>
        </div>
        {isOpen && (
          <ul
            className="absolute z-10 max-h-48 w-full overflow-y-auto rounded-md border border-gray-300 bg-white shadow-md"
            role="listbox"
          >
            {options.map(option => (
              <li
                key={option.value}
                className={clsx(
                  'cursor-pointer px-3 py-2',
                  selectedItems.includes(option.value) &&
                    'bg-gray-100 text-black',
                )}
                onClick={() => handleToggleItem(option.value)}
                role="option"
                aria-selected={selectedItems.includes(option.value)}
                tabIndex={0}
                onKeyDown={e => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleToggleItem(option.value);
                  }
                }}
              >
                <div className="ml-5 flex items-center space-x-3">
                  <Input
                    type="checkbox"
                    height="26px"
                    containerClassName="relative mt-2 flex items-center  "
                    inputClassName="focus:outline-none w-[24] bg-light-gray-300"
                    checked={selectedItems.includes(option.value)}
                    onChange={() => handleToggleItem(option.value)}
                    name={`option-${option.value}`}
                  />
                  <img
                    src={option.iconUrl || ServiceIcon}
                    alt={t('MultiSelect.option_icon')}
                    width={30}
                    height={30}
                    className="pointer-events-none"
                  />
                  <span className="font-airbnb text-[12px]">
                    {option.label}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default MultiSelect;
