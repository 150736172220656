import React, { type ChangeEvent, useState } from 'react';

import AddIcon from '../../../assets/icons/addIcon2.svg';

interface ImageUploaderProps {
  onImageUpload: (file: File) => void;
  accept?: string;
  previewSrc?: string;
  width?: number;
  height?: number;
  AddIconClassName?: string;
  AddIconWidth?: number;
  AddIconHeight?: number;
  MainContainerWidth?: string;
  MainContainerHeight?: string;
  bottomText?: string;
  borderColor?: string;
}

const Uploader: React.FC<ImageUploaderProps> = ({
  onImageUpload,
  accept = 'image/*',
  width = 77,
  previewSrc,
  AddIconClassName,
  height = 33,
  AddIconWidth = 20,
  AddIconHeight = 20,
  MainContainerWidth = '77px',
  MainContainerHeight = '33px',
  bottomText = '',
  borderColor = '',
}) => {
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target?.files && event.target.files[0]) {
      onImageUpload(event.target.files[0]);
      setImagePreview(URL.createObjectURL(event.target.files[0]));
    }
  };

  return (
    <div
      style={{
        width: MainContainerWidth,
        height: MainContainerHeight,
        borderColor: borderColor || 'transparent',
      }}
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className={`h-[${MainContainerHeight}] w-[${MainContainerWidth}] relative flex cursor-pointer  flex-col items-center justify-center border border-gray-300 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
    >
      <input
        type="file"
        id="dropzone-file"
        onChange={handleImageChange}
        name="myfile"
        accept={accept}
        multiple
        className="absolute size-full opacity-0"
        aria-label="Upload images"
      />
      {imagePreview || previewSrc ? (
        <img
          src={imagePreview || previewSrc}
          width={width}
          height={height}
          alt="Preview"
          className="size-full rounded-lg object-cover"
        />
      ) : (
        <>
          <img
            src={AddIcon}
            alt="edit"
            width={AddIconWidth}
            height={AddIconHeight}
            className={AddIconClassName}
          />
          {bottomText && (
            <div className="mt-2 text-center text-sm">{bottomText}</div>
          )}
        </>
      )}
    </div>
  );
};

export default Uploader;
