import CrossIcon from 'assets/icons/cross.svg';
import clsx from 'clsx';
import type { FC } from 'react';
import React from 'react';

import Button from '../Button';

export interface ModalProps {
  id?: string;
  title?: string;
  body?: string | React.ReactNode;
  isOpen: boolean;
  acceptText?: string;
  declineText?: string;
  onClose: () => void;
  onAccept?: () => void;
  onDecline?: () => void;
  headerBgColor?: string;
  headerTextColor?: string;
  acceptButtonBgColor?: string;
  crossButtonBgColor?: string;
}

const Modal: FC<ModalProps> = ({
  id,
  title,
  body,
  isOpen,
  onClose,
  acceptText = 'I accept',
  declineText = 'No',
  onAccept,
  onDecline,
  headerBgColor = '',
  headerTextColor = '',
  acceptButtonBgColor = 'bg-lime-green',
  crossButtonBgColor = 'bg-dark-blue',
}) => {
  const overlayClassName = clsx(
    'fixed inset-0 z-40 bg-black bg-opacity-60 transition-opacity',
    {
      'opacity-100': isOpen,
      'pointer-events-none opacity-0': !isOpen,
    },
  );

  const headerClassName = clsx(headerBgColor, title ? 'p-5' : 'px-5 pt-8');

  return (
    <>
      <div
        className={overlayClassName}
        hidden={!isOpen}
        aria-label="button"
        role="button"
        tabIndex={0}
      />
      <div
        id={id}
        aria-hidden={!isOpen}
        className={`fixed inset-0 z-50 flex items-center justify-center ${isOpen ? '' : 'hidden'}`}
      >
        <div className="relative w-full max-w-2xl rounded-xl bg-white shadow">
          <div
            className={`${headerClassName} relative flex items-center justify-between rounded-b-none rounded-t-[11px]`}
          >
            {title && (
              <h3
                className={`font-airbnb text-xl font-semibold ${headerTextColor}`}
              >
                {title}
              </h3>
            )}
            <div className="absolute right-3">
              <button
                onClick={onClose}
                type="button"
                className={`flex items-center justify-center rounded-full ${crossButtonBgColor} p-2 text-base text-white`}
              >
                <img src={CrossIcon} alt="cross" />
                <span className="sr-only">Close modal</span>
              </button>
            </div>
          </div>

          <div className="px-4">
            <div className="font-airbnb text-base leading-relaxed">{body}</div>
          </div>
          {(onAccept || onDecline) && (
            <div className="flex items-center justify-center gap-4 rounded-b border-gray-200 p-4 dark:border-gray-600">
              {onDecline && (
                <Button
                  htmlType="reset"
                  className="ml-3 rounded-3xl border border-dark-blue bg-white px-5 py-2.5 text-sm font-medium text-dark-blue"
                  onClick={onDecline}
                >
                  {declineText}
                </Button>
              )}
              {onAccept && (
                <Button
                  className={`danger-class rounded-3xl ${acceptButtonBgColor} px-5 py-3 text-center text-sm font-medium text-white focus:outline-none`}
                  onClick={onAccept}
                >
                  {acceptText}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Modal;
