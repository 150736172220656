import Config from 'constants/index';
import type { DocumentFormValidationType } from 'constants/validationSchema';
import { getTokenFromLocalStorage } from 'contexts/authContext';
import type { PaginationProps, Product } from 'types/entities';
import type {
  GetDocumentTypeResponse,
  GetMyOrdersResponse,
  GetProductsResponse,
  PublishProductPayload,
  UpdateCategoryPayload,
  UpdateDocumentPayload,
  UpdateDocumentTypeResponse,
  UpdateProductResponse,
  UpdateViewListingResponse,
} from 'types/requestsAndResponses';
import { axiosService } from 'utils/axios';

import type { AddProductPayload } from './types/requestsAndResponses';

const token = getTokenFromLocalStorage();

export const postProductTypes = async (data: any) => {
  const response = await axiosService(
    `${Config.backendApiUrl}/catalogue/admin/v1/product_type/`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },

      data: JSON.stringify(data),
    },
  );
  return response;
};
export const updateProductType = async (data: any, productId: string) => {
  const response = await axiosService(
    `${Config.backendApiUrl}/catalogue/admin/v1/product_type/${productId}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },
      data: JSON.stringify(data),
    },
  );
  return response;
};

export const deleteProduct = async (data: any, productId: string) => {
  const response = await axiosService(
    `${Config.backendApiUrl}/catalogue/admin/v1/products/${productId}`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },
      data: JSON.stringify(data),
    },
  );
  return response;
};

export const postCategory = async (data: any) => {
  const response = await axiosService(
    `${Config.backendApiUrl}/catalogue/admin/v1/category-list/`,
    {
      method: 'post',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },
      data: JSON.stringify(data),
    },
  );
  return response;
};

export const updateCategory = async (data: UpdateCategoryPayload) => {
  const { uuid } = data;
  const response = await axiosService(
    ` ${Config.backendApiUrl}/catalogue/admin/v1/category-list/${uuid}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },
      data: JSON.stringify(data),
    },
  );
  return response;
};

export const deleteCategory = async (data: any, categoryId: string) => {
  const response = await axiosService(
    `${Config.backendApiUrl}/catalogue/admin/v1/category-list/${categoryId}`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },
      data: JSON.stringify(data),
    },
  );
  return response;
};

export const getProducts = async ({
  currentPage,
  pageSize,
  filter,
  searchValue,
}: PaginationProps & {
  searchValue?: string;
}): Promise<GetProductsResponse> => {
  let url = `${Config.backendApiUrl}/catalogue/admin/v1/products/products-listing`;

  const queryParams = new URLSearchParams();

  if (currentPage !== undefined) {
    queryParams.append('page_no', currentPage.toString());
  }

  if (pageSize !== undefined) {
    queryParams.append('page_size', pageSize.toString());
  }

  if (filter) {
    queryParams.append('filters', `status=${filter}`);
  }

  if (searchValue) {
    queryParams.append('search', searchValue);
  }
  if (queryParams.toString()) {
    url += `?${queryParams.toString()}`;
  }
  const response = await axiosService<GetProductsResponse>(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const getProductId = async (productId: string) => {
  const data = await axiosService<GetProductsResponse>(
    `${Config.backendApiUrl}/catalogue/unauthenticated/v1/products/${productId}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
  return data;
};

export const updateProduct = async (payload: any, uuid: string) => {
  const data = await axiosService<UpdateProductResponse>(
    `${Config.backendApiUrl}/catalogue/authenticated/v1/products/update-product/${uuid}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(payload),
    },
  );
  return data;
};

export const updatePublishProduct = async ({
  uuid,
  payload,
}: {
  uuid: string;
  payload: any;
}) => {
  const data = await axiosService(
    `${Config.backendApiUrl}/catalogue/admin/v1/products/${uuid}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(payload),
    },
  );
  return data;
};

export const registerUser = async (accessToken: string) => {
  const data = await axiosService(
    `${Config.backendApiUrl}/auth/v1/users/admin-register/`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'Content-type': 'application/json',
      },
    },
  );
  return data;
};

export const addProductPost = async (
  payload: AddProductPayload,
): Promise<AddProductPayload> => {
  const response = await axiosService<AddProductPayload>(
    `${Config.backendApiUrl}/catalogue/authenticated/v1/products/`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },
      data: JSON.stringify(payload),
    },
  );
  return response;
};

export const getCategories = async ({
  currentPage,
  pageSize,
  filter,
}: PaginationProps) => {
  let url = `${Config.backendApiUrl}/catalogue/admin/v1/category-list/`;

  const queryParams = new URLSearchParams();

  if (currentPage !== undefined) {
    queryParams.append('page_no', currentPage.toString());
  }

  if (pageSize !== undefined) {
    queryParams.append('page_size', pageSize.toString());
  }

  if (filter) {
    queryParams.append('search', filter);
  }

  if (queryParams.toString()) {
    url += `?${queryParams.toString()}&all=${true}`;
  }
  const response = await axiosService<any>(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  return response;
};

export const getProductTypes = async ({
  currentPage,
  pageSize,
  filter,
}: PaginationProps) => {
  let url = `${Config.backendApiUrl}/catalogue/unauthenticated/v1/product_type/`;

  const queryParams = new URLSearchParams();

  if (currentPage) {
    queryParams.append('page_no', currentPage.toString());
  }

  if (pageSize) {
    queryParams.append('page_size', pageSize.toString());
  }

  if (filter) {
    queryParams.append('search', filter);
  }

  if (queryParams.toString()) {
    url += `?${queryParams.toString()}`;
  }

  const response = await axiosService<any>(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  return response;
};
export const updateProductListing = async (uuid: string) => {
  const data = await axiosService<UpdateViewListingResponse>(
    `${Config.backendApiUrl}/catalogue/admin/v1/products/approve-product/${uuid}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  );
  return data;
};

export const deleteProductType = async (productTypeId: string) => {
  const response = await axiosService(
    `${Config.backendApiUrl}/catalogue/admin/v1/product_type/${productTypeId}`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },
    },
  );
  return response;
};

export const getProductDetails = async (
  product_uuid: string,
): Promise<Product> => {
  const data = await axiosService<Product>(
    `${Config.backendApiUrl}/catalogue/admin/v1/products/${product_uuid}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  );
  return data;
};

export const rejectProductListing = async (payload: {
  uuid: string;
  decline_reason: string;
}) => {
  const response = await axiosService<UpdateViewListingResponse>(
    `${Config.backendApiUrl}/catalogue/admin/v1/products/decline-product/${payload.uuid}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(payload),
    },
  );
  return response;
};

export const UploadImage = async (payload: FormData) => {
  const response = await axiosService(
    `${Config.backendApiUrl}/auth/v1/documents/`,
    {
      method: 'POST',
      data: payload,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};

export const searchAddons = async ({ filter }: { filter: string }) => {
  let url = `${Config.backend.url}/catalogue/user/v1/products/current_users_addons`;
  if (filter) {
    url += `&filters=search=${filter}`;
  }
  const response = await axiosService(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'application/json',
    },
  });
  return response;
};

export const publishProduct = async (payload: PublishProductPayload) => {
  const data = await axiosService(
    `${Config.backendApiUrl}/catalogue/admin/v1/products/`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },
      data: JSON.stringify(payload),
    },
  );
  return data;
};

export const getDocumentTypes = async ({
  currentPage,
  pageSize,
  filter,
}: PaginationProps) => {
  let url = `${Config.backendApiUrl}/catalogue/admin/v1/document_types/?page_no=${currentPage}&page_size=${pageSize}`;
  if (filter) {
    url += `&filters=search=${filter}`;
  }
  const response = await axiosService<GetDocumentTypeResponse>(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'application/json',
    },
  });
  return response;
};
export const deleteDocumentType = async (documentTypeId: string) => {
  const response = await axiosService(
    `${Config.backendApiUrl}/catalogue/admin/v1/document_types/${documentTypeId}`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },
    },
  );
  return response;
};
export const postDocumentType = async (data: DocumentFormValidationType) => {
  const response = await axiosService<GetDocumentTypeResponse>(
    `${Config.backendApiUrl}/catalogue/admin/v1/document_types/`,
    {
      method: 'post',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },
      data: JSON.stringify(data),
    },
  );
  return response;
};
export const updateDocumentType = async (data: UpdateDocumentPayload) => {
  const { uuid } = data;
  const response = await axiosService<UpdateDocumentTypeResponse>(
    ` ${Config.backendApiUrl}/catalogue/admin/v1/document_types/${uuid}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },
      data: JSON.stringify(data),
    },
  );
  return response;
};
export const getMyOrders = async ({
  currentPage,
  pageSize,
}: PaginationProps) => {
  let url = `${Config.backendApiUrl}/order/v1/orders/admin/orders?page_no=${currentPage}&page_size=${pageSize}`;
  const response = await axiosService<GetMyOrdersResponse>(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'application/json',
    },
  });
  return response;
};

export const getSettings = async () => {
  let url = `${Config.backendApiUrl}/auth/v1/configurations/`;
  const response = await axiosService<any>(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'application/json',
    },
  });
  return response;
};

export const updateSettings = async ({ payload }: { payload: any }) => {
  const data = await axiosService(
    `${Config.backendApiUrl}/auth/v1/configurations/`,
    {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(payload),
    },
  );
  return data;
};
