import CrossIcon from 'assets/icons/cross.svg';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../Button';
import Modal from '../Modal';

interface LanguageModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function LanguageModal({ isOpen, onClose }: LanguageModalProps) {
  const { t, i18n } = useTranslation();
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      body={
        <div className="h-[180px] max-h-[80vh] overflow-y-auto">
          <img src={CrossIcon} alt="cross" />
          <div className="pb-2 font-airbnb">{t('language_modal.language')}</div>
          <hr className="color-light-gray-clr" />
          <div className="pt-5 font-airbnb text-[22px] font-medium">
            {t('language_modal.chooseLanguage')}
          </div>
          <div className="left-[27px] flex w-[181px] gap-3 pt-5">
            <div
              className={`w-[181px] rounded-lg ${
                i18n.language === 'en'
                  ? 'border-2 border-charcoal-gray'
                  : 'border border-transparent'
              }`}
            >
              <Button
                className="px-8 py-3 font-airbnb text-sm font-medium text-black"
                onClick={() => changeLanguage('en')}
              >
                {t('language_modal.english')}
              </Button>
            </div>
            <div
              className={`w-[181px] rounded-lg ${
                i18n.language === 'de'
                  ? 'border-2 border-charcoal-gray'
                  : 'border border-transparent'
              }`}
            >
              <Button
                className="px-8 py-3 font-airbnb text-sm font-medium text-black"
                onClick={() => changeLanguage('de')}
              >
                {t('language_modal.dutch')}
              </Button>
            </div>
          </div>
        </div>
      }
      onClose={onClose}
    />
  );
}
