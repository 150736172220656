import Button from 'app/components/Button';
import Checkbox from 'app/components/Checkbox';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Attribute } from 'types/entities';

import DeleteIcon from '../../../assets/icons/deleteIcon.svg';

interface AttributesTableProps {
  data: Attribute[];
  onDeleteRow: (index: number) => void;
  onAttributeChange: (index: number, field: string, value: any) => void;
}

const AttributesTable: React.FC<AttributesTableProps> = ({
  data,
  onDeleteRow,
  onAttributeChange,
}) => {
  const { t } = useTranslation();

  const handleChange = (
    index: number,
    field: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value =
      field === 'display_on_product' || field === 'is_available_for_variants'
        ? event.target.checked
        : event.target.value;

    onAttributeChange(index, field, value);
  };

  const attributeData = data.filter(attr => !attr.is_deleted);

  return (
    <div className="my-10 flex w-full justify-center overflow-x-auto">
      {attributeData.length === 0 ? (
        <div className="py-10 text-center text-gray-500">
          {t('ProductListing.info_message')}
        </div>
      ) : (
        <div className="w-full max-w-4xl">
          <table className="min-w-full border-collapse text-center">
            <thead>
              <tr className="bg-white">
                <th className="px-4 py-2 text-left text-[12px] font-medium">
                  {t('ProductListing.name')}
                </th>
                <th className="px-8 py-2 text-left text-[12px] font-medium">
                  {t('ProductListing.value')}
                </th>
                <th className="px-4 py-2 text-left text-[12px] font-medium">
                  {t('ProductListing.Visible_product_page')}
                </th>
                <th className="px-4 py-2 text-left text-[12px] font-medium">
                  {t('ProductListing.used_for_variations')}
                </th>
                <th className="px-4 py-2 text-left text-[12px] font-medium">
                  {t('ProductListing.action')}
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-gray-100 text-[12px] font-medium">
              {attributeData.map((item, index) => (
                <tr key={item.id} className="text-left">
                  <td className="rounded-es-lg rounded-ss-lg p-4">
                    {item.name}
                  </td>
                  <td className="px-6 py-4">{item.attribute_option}</td>
                  <td className="px-14 py-4">
                    <label
                      htmlFor={`display_on_product_${item.id}`}
                      className="sr-only"
                    >
                      {t('ProductListing.Visible_product_page')}
                    </label>
                    <Checkbox
                      id={`display_on_product_${item.id}`}
                      name="display_on_product"
                      checked={item.display_on_product}
                      className="size-4"
                      onChange={e =>
                        handleChange(index, 'display_on_product', e)
                      }
                    />
                  </td>
                  <td className="px-12 py-4">
                    <label
                      htmlFor={`is_available_for_variants_${item.id}`}
                      className="sr-only"
                    >
                      {t('ProductListing.used_for_variations')}
                    </label>
                    <Checkbox
                      id={`is_available_for_variants_${item.id}`}
                      name="is_available_for_variants"
                      checked={item.is_available_for_variants}
                      className="size-4"
                      onChange={e =>
                        handleChange(index, 'is_available_for_variants', e)
                      }
                    />
                  </td>
                  <td className="rounded-br-lg rounded-se-lg px-6 py-2">
                    <Button
                      htmlType="button"
                      onClick={() => onDeleteRow(index)}
                      className="mt-2 text-red-600 hover:text-red-800"
                    >
                      <img
                        src={DeleteIcon}
                        alt={t('ProductListing.delete')}
                        width={20}
                        height={20}
                      />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AttributesTable;
