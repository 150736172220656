import { z } from 'zod';

export const ProductTypeFormValidation = z.object({
  title: z.string().min(1, { message: 'Title is required' }),
  description: z.string().min(1, { message: 'Description is required' }),
});
export const CategoriesFormValidation = z.object({
  title: z.string().min(1, { message: 'Category name is required' }),
  description: z.string().min(1, { message: 'Description is required' }),
  image: z.string(),
  category_uuid: z.string().optional(),
  parent_category: z.string().optional(),
});
export const DocumentFormValidation = z.object({
  title: z.string().min(1, { message: 'Document is required' }),
  verification_required: z.boolean(),
  is_active: z.boolean(),
});

export type FormValidationType = z.infer<typeof CategoriesFormValidation>;
export type DocumentFormValidationType = z.infer<typeof DocumentFormValidation>;

const ProductTypeSchema = z.object({
  product_type_uuid: z
    .string()
    .min(1, { message: 'Please select a product type' }),
  pricing: z
    .object({
      base_price: z.number().min(1, { message: 'Base Price is required' }),
    })
    .optional(),

  attributes: z.array(
    z
      .object({
        name: z.string().min(1, { message: 'Name is required ' }),
        attribute_option: z
          .string()
          .min(1, { message: 'Attribute option is required' }),
        display_on_product: z.boolean(),
        is_available_for_variants: z.boolean(),
        uuid: z.string().optional(),
        is_deleted: z.boolean().optional(),
      })
      .optional(),
  ),

  variants: z.array(
    z
      .object({
        image: z.string().min(1, { message: 'Image is Required' }).optional(),
        variant_option: z
          .string()
          .min(1, { message: 'Variant option is required' }),
        isStock: z.boolean(),
        is_available_on_product: z.boolean(),

        price: z.number().min(0, { message: 'Price must be at least 0' }),
        description: z.string().optional(),

        uuid: z.string().optional(),
        is_deleted: z.boolean().optional(),
      })
      .optional(),
  ),
  addons: z.array(
    z.object({
      uuid: z.string().min(1, 'UUID is required').optional(),
      name: z.string().min(1, 'Name is required'),
      price: z.number().positive('Price must be positive'),
      description: z.string().optional(),
      display_on_product: z.boolean().optional(),
      image: z.string().url().optional(),
      is_deleted: z.boolean().optional(),
    }),
  ),

  specifications: z.array(
    z
      .object({
        name: z.string().min(1, { message: 'Name is required' }),
        value: z.string().min(1, { message: 'Value is required' }),
        description: z.string().optional(),
        display_on_product: z.boolean().optional(),
        uuid: z.string().optional(),
        is_deleted: z.boolean().optional(),
      })
      .optional(),
  ),
});

export const ProductTypeFormScheme = z.object({
  title: z.string().min(1, { message: 'Title is required' }),
  description: z.string().min(1, { message: 'Description is required' }),
  category_uuid: z.string().min(1, { message: 'Please select a category' }),
  images: z.array(z.string().min(1, { message: 'Image is Required' })),
  status: z.string().optional(),

  product_details: z.array(ProductTypeSchema),
});
