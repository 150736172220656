import Button from 'app/components/Button';
import Input from 'app/components/Input';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DeleteIcon from '../../../assets/icons/deleteIcon.svg';
import PlaceHolderImage from '../../../assets/img/placeholderImage.png';

interface CommonTableProps {
  data: any[];
  type: 'addOns' | 'specifications';
  register: any;
  onDeleteRow: (index: number) => void;
  index: any;
  UploadedImage?: File;
}

const Table: React.FC<CommonTableProps> = ({
  data,
  type,
  register,
  onDeleteRow,
  index,
  UploadedImage,
}) => {
  const { t } = useTranslation();
  const isAddOnsTable = type === 'addOns';
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  useEffect(() => {
    if (UploadedImage) {
      setImagePreview(URL.createObjectURL(UploadedImage));
    }
  }, [UploadedImage]);
  return (
    <div className="flex w-full justify-center overflow-x-auto">
      {data.length === 0 ? (
        <div className="py-10 text-center text-gray-500" />
      ) : (
        <div className="w-[95%] max-w-4xl">
          <table className="min-w-full border-collapse text-center">
            <thead>
              <tr className="font-airbnb text-[12px] font-semibold">
                {isAddOnsTable ? (
                  <>
                    <th className="border-b-0 px-4 py-2">
                      {t('ProductListing.image')}
                    </th>
                    <th className="border-b-0 px-4 py-2">
                      {t('ProductListing.name')}
                    </th>
                    <th className="border-b-0 px-4 py-2">
                      {t('ProductListing.price')}
                    </th>
                  </>
                ) : (
                  <>
                    <th className="border-b-0 px-4 py-2">
                      {t('ProductListing.name')}
                    </th>
                    <th className="border-b-0 px-4 py-2">
                      {t('ProductListing.value')}
                    </th>
                  </>
                )}
                <th className="border-b-0 px-4 py-2">
                  {t('ProductListing.product_display')}
                </th>
                <th className="border-b-0 p-2">
                  {t('ProductListing.description_note')}
                </th>
                <th className="border-b-0 px-4 py-2">
                  {t('ProductListing.action')}
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200 bg-gray-100 text-[12px] font-medium">
              {/* Table Data */}
              {data.map((item, Tableindex) => (
                <tr
                  key={item.id || Tableindex}
                  className="border-t border-gray-200 bg-gray-100"
                >
                  {isAddOnsTable ? (
                    <>
                      <td className="rounded-l-lg px-6 py-1">
                        <img
                          src={imagePreview || PlaceHolderImage}
                          alt={t('ProductListing.image')}
                          width={77}
                          height={33}
                        />
                      </td>
                      <td className="px-6 py-1">{item.name}</td>
                      <td className="px-6 py-1">{item.price}</td>
                    </>
                  ) : (
                    <>
                      <td
                        className={clsx('px-8 py-1', {
                          'rounded-tl-lg rounded-bl-lg':
                            type === 'specifications',
                        })}
                      >
                        {item.name}
                      </td>
                      <td className="px-8 py-1">{item.value}</td>
                    </>
                  )}
                  <td className="px-8 py-1">
                    <Input
                      type="checkbox"
                      placeholder="name"
                      containerClassName="relative flex items-center"
                      inputClassName="focus:outline-none w-16 border border-gray-200 font-normal"
                      alt="specifications"
                      height="16px"
                      {...register(
                        `product_details[${index}].${type}[${Tableindex}].display_on_product`,
                      )}
                    />
                  </td>
                  <td className="px-8 py-1">
                    <Input
                      height="30px"
                      containerClassName="relative mt-2 flex items-center w-300"
                      inputClassName="focus:outline-none text-[14px] text-center"
                      {...register(
                        `product_details[${index}].${type}[${Tableindex}].description`,
                      )}
                    />
                  </td>
                  <td className="rounded-r-lg p-1">
                    <Button
                      htmlType="button"
                      onClick={() => onDeleteRow(Tableindex)}
                    >
                      <img
                        src={DeleteIcon}
                        alt={t('ProductListing.delete')}
                        width={20}
                        height={20}
                      />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Table;
