import { Table } from 'antd';
import React from 'react';

interface ProductDetailTableProps {
  item: { uuid: string; variants: string; value: string }[];
}

const ProductDetailTable: React.FC<ProductDetailTableProps> = ({ item }) => {
  const columns = [
    {
      title: 'UUID',
      dataIndex: 'uuid',
      key: 'uuid',
      className: 'bg-gray-100 font-semibold',
    },
    {
      title: 'Variants',
      dataIndex: 'variants',
      key: 'variants',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  return (
    <Table
      dataSource={item}
      columns={columns}
      rowKey="uuid"
      className="relative w-full table-fixed border"
      pagination={false}
    />
  );
};

export default ProductDetailTable;
