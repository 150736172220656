import Input from 'app/components/Input';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AddIcon from '../../../assets/icons/addIcon2.svg';
import SpecificationsTable from './SpecificationsTable';

interface SpecificationProps {
  register: any;
  watch: any;
  setValue: any;
  index: any;
}
const Specifications: React.FC<SpecificationProps> = ({
  register,
  watch,
  setValue,
  index,
}) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const specifications =
    watch(`product_details[${index}].specifications`) || [];

  const addSpecification = () => {
    const newSpecification = {
      name: getValues(`product_details[${index}].specifications.name`) || '',
      value: getValues(`product_details[${index}].specifications.value`) || '',
      display_on_product: getValues(
        `product_details[${index}].specifications.display_on_product`,
      ),
      description:
        getValues(`product_details[${index}].specifications.description`) || '',
    };

    if (!newSpecification.name.trim() || !newSpecification.value.trim()) return;

    const updatedSpecifications = [...specifications, newSpecification];
    setValue(`product_details[${index}].specifications`, updatedSpecifications);

    // Clear form fields
    setValue(`product_details[${index}].specifications.name`, '');
    setValue(`product_details[${index}].specifications.value`, '');
    setValue(
      `product_details[${index}].specifications.display_on_product`,
      false,
    );
  };

  const removeSpecification = (itemIndex: number) => {
    const specification = specifications[itemIndex];
    const isExistingItem = specification?.uuid;

    if (isExistingItem) {
      const updatedSpecifications = specifications.map(
        (spec: any, idx: number) =>
          idx === itemIndex ? { ...spec, is_deleted: true } : spec,
      );
      setValue(
        `product_details[${index}].specifications`,
        updatedSpecifications,
      );
    } else {
      const updatedSpecifications = specifications.filter(
        (_: any, idx: number) => idx !== itemIndex,
      );
      setValue(
        `product_details[${index}].specifications`,
        updatedSpecifications,
      );
    }
  };

  return (
    <>
      {/* Specifications */}
      <div className="h-auto w-full">
        <div className="flex h-auto w-full">
          <div className="mt-12 flex h-[55px] w-1/5 rounded-r-xl bg-light-gray ">
            <h1 className="items-center p-4 font-airbnb text-[16px] font-bold">
              {t('ProductListing.specifications')}
            </h1>
          </div>
          <div className="m-5 w-3/4">
            <div className="flex w-full justify-between">
              <div className="my-4">
                <label
                  htmlFor={t('ProductListing.name')}
                  className="p-2 font-airbnb text-[14px] font-medium"
                >
                  {t('ProductListing.name')}
                </label>
                <Input
                  placeholder="name"
                  containerClassName="relative flex items-center"
                  inputClassName="focus:outline-none w-[220px] border border-gray-200 shadow-sm"
                  height="38px"
                  alt="Specification"
                  {...register(`product_details[${index}].specifications.name`)}
                />
              </div>
              <div className="my-4 ml-2 w-[243px]">
                <label
                  htmlFor={t('ProductListing.value')}
                  className="p-2 font-airbnb text-[14px] font-medium"
                >
                  {t('ProductListing.value')}
                </label>
                <Input
                  placeholder={t('ProductListing.value')}
                  containerClassName="relative flex items-center"
                  inputClassName="focus:outline-none border border-gray-200 shadow-sm"
                  height="38px"
                  alt={t('ProductListing.value')}
                  {...register(
                    `product_details[${index}].specifications.value`,
                  )}
                />
              </div>
              <div className="my-4 ml-5 w-[150px] justify-center ">
                <label
                  htmlFor={t('ProductListing.display_product')}
                  className="mb-2 font-airbnb text-[14px] font-medium"
                >
                  {t('ProductListing.display_product')}
                </label>
                <Input
                  type="checkbox"
                  height="16px"
                  className="ml-2"
                  containerClassName="relative flex px-8 py-2 mt-2 "
                  inputClassName="focus:outline-none w-[100px] border border-gray-200  "
                  {...register(
                    `product_details[${index}].specifications.display_on_product`,
                  )}
                />
              </div>
              <div className="mx-5 mt-10">
                <button
                  type="button"
                  className="cursor-pointer border-none bg-transparent p-0"
                  onClick={addSpecification}
                  aria-label={t('ProductListing.add')}
                >
                  <img
                    src={AddIcon}
                    alt={t('ProductListing.add')}
                    width={40}
                    height={40}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Table */}
        {Array.isArray(specifications) ? (
          <SpecificationsTable
            data={specifications || []}
            register={register}
            removeSpecification={removeSpecification}
            index={index}
          />
        ) : null}
      </div>
    </>
  );
};

export default Specifications;
