import backend from './backend';

type Environment = 'production' | 'staging';

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as Environment;

export default {
  backend: backend[ENVIRONMENT],
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  backendApiUrl: process.env.REACT_APP_BACKEND_API_URL,
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  },
};
