import { DatePicker } from 'antd';
import type { Dayjs } from 'dayjs';
import React from 'react';
import { formatDateTime } from 'utils/commonFunction';

interface DatePickerSectionProps {
  checkInDate: string | null;
  checkOutDate: string | null;
  setCheckInDate: (date: string | null) => void;
  setCheckOutDate: (date: string | null) => void;
  isCheckInPickerOpen: boolean;
  setIsCheckInPickerOpen: (open: boolean) => void;
  isCheckOutPickerOpen: boolean;
  setIsCheckOutPickerOpen: (open: boolean) => void;
  disabledDates: (date: Dayjs) => boolean;
  disabledDatesCheckIn: (date: Dayjs) => boolean;
  availabilityDates: { start_datetime?: string; end_datetime?: string }[];
}

const DatePickerCart: React.FC<DatePickerSectionProps> = ({
  checkInDate,
  checkOutDate,
  setCheckInDate,
  setCheckOutDate,
  isCheckInPickerOpen,
  setIsCheckInPickerOpen,
  isCheckOutPickerOpen,
  setIsCheckOutPickerOpen,
  disabledDates,
  availabilityDates,
  disabledDatesCheckIn,
}) => (
  <div className="mt-4 flex h-[60px] w-full cursor-pointer flex-row ">
    <div className="ml-5 flex-1 border-r border-gray-500">
      <span className="text-[14px] font-semibold">Check In</span>
      <div className="text-[14px] font-semibold">
        {checkInDate ||
          (availabilityDates[0]?.start_datetime &&
            formatDateTime(
              availabilityDates[0].start_datetime,
              'YYYY-MM-DD HH:mm',
            ))}
        {isCheckInPickerOpen && (
          <DatePicker
            open={isCheckInPickerOpen}
            showTime
            format="YYYY-MM-DD HH:mm "
            onChange={date => {
              setCheckInDate(date?.format('YYYY-MM-DD HH:mm') || null);
              setIsCheckInPickerOpen(false);
            }}
            onOpenChange={open => {
              if (!open) setIsCheckInPickerOpen(false);
            }}
            style={{ visibility: 'hidden', width: 0 }}
            disabledDate={disabledDates}
          />
        )}
      </div>
    </div>

    <div className="ml-5 flex-1">
      <span className="text-[14px] font-semibold">Check Out</span>
      <div className="text-[14px] font-semibold">
        {checkOutDate ||
          (availabilityDates[0]?.end_datetime &&
            formatDateTime(
              availabilityDates[0].end_datetime,
              'YYYY-MM-DD HH:mm',
            ))}
        {isCheckOutPickerOpen && (
          <DatePicker
            open={isCheckOutPickerOpen}
            showTime
            format="YYYY-MM-DD HH:mm"
            onChange={date => {
              setCheckOutDate(date?.format('YYYY-MM-DD HH:mm') || null);
              setIsCheckOutPickerOpen(false);
            }}
            onOpenChange={open => {
              if (!open) setIsCheckOutPickerOpen(false);
            }}
            style={{ visibility: 'hidden', width: 0 }}
            disabledDate={disabledDatesCheckIn}
          />
        )}
      </div>
    </div>
  </div>
);

export default DatePickerCart;
