import React from 'react';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  className?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  label,
  name,
  checked = false,
  defaultChecked = false,
  disabled = false,
  className = '',
  error,
  onChange,
  ...inputProps
}) => {
  if (!name) {
    return null;
  }

  return (
    <div className={`flex items-center ${className}`}>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        defaultChecked={defaultChecked}
        className="size-4 rounded border-gray-300 checked:border-blue-600 checked:bg-blue-600 focus:outline-none focus:ring-blue-500 disabled:opacity-50"
        disabled={disabled}
        onChange={onChange}
        {...inputProps}
      />
      {label && (
        <label htmlFor={id} className="ml-2 text-sm font-medium text-gray-900">
          {label}
        </label>
      )}
      {error && <p className="ml-2 text-sm text-red-600">{error}</p>}
    </div>
  );
};

export default Checkbox;
