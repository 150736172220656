import { useUploadImage } from 'hooks/queries';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CrossIcon from '../../../assets/icons/crossCircle.svg';
import UploadIcon from '../../../assets/icons/uploadIcon.svg';
import Button from '../Button';

interface ImageUploaderProps {
  name: string;
  label?: string;
  maxImages?: number;
  updatedImage?: string[];
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  name,
  label,
  maxImages = 6,
  updatedImage,
}) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const [images, setImages] = useState<File[]>([]);
  const { mutate: UploadImage, data } = useUploadImage();
  const [fileUrls, setFileUrls] = useState<string[]>(updatedImage || []);

  setValue('images', fileUrls);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      const newImages = [...images, ...files].slice(0, maxImages);
      setImages(newImages);
      setValue(name, newImages);

      const formData = new FormData();
      files.forEach(file => {
        formData.append('file', file);
      });

      UploadImage(formData);
    }
  };

  useEffect(() => {
    if (data?.url) {
      setFileUrls(prev => [...prev, data.url]);
      setValue(name, [...fileUrls, data.url]);
    }
  }, [data]);

  const handleRemoveImage = (index: number) => {
    const newImages = images.filter((_, i) => i !== index);
    const UpdatedImage = fileUrls.filter((_, i) => i !== index);
    setFileUrls(UpdatedImage);
    setImages(newImages);
    setValue(name, newImages);
  };

  return (
    <div className="w-full md:col-span-1 lg:col-span-1">
      <div className="h-auto w-full rounded-3xl border border-zinc-100 bg-white shadow-md">
        {label && (
          <h1 className="my-5 text-center font-airbnb text-[20px] font-bold">
            {label}
          </h1>
        )}
        <div className="bg-light-gray-400 m-5 flex items-center justify-center rounded-3xl">
          <label
            htmlFor="dropzone"
            className="flex h-64 w-full cursor-pointer flex-col items-center justify-center"
          >
            <div className="flex flex-col items-center justify-center pb-6 pt-5">
              <img src={UploadIcon} alt="Upload Icon" width={41} height={41} />
              <p className="text-blue-shade-500 mt-2 font-airbnb text-[16px] font-normal underline underline-offset-2">
                {t('ProductListing.click_to_upload')}
              </p>
            </div>
            <input
              type="file"
              id="dropzone"
              onChange={handleImageChange}
              name="myfile"
              multiple
              accept="image/*"
              className="hidden"
              aria-label="Upload images"
            />
          </label>
        </div>
      </div>
      <div className="mt-5 grid grid-cols-3 gap-5 p-2">
        {fileUrls.map((imageUrl, index) => (
          <div key={index} className="relative h-36 w-full">
            <img
              src={imageUrl}
              alt={`Uploaded ${index}`}
              className="size-[150px] rounded-xl border border-gray-300"
            />
            <Button
              htmlType="button"
              className="absolute left-[-16px] top-[-12px] z-20 rounded-full p-1 text-white"
              onClick={() => handleRemoveImage(index)}
              aria-label={`Remove image ${index + 1}`}
            >
              <img src={CrossIcon} alt="Cross Icon" width={50} height={50} />
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageUploader;
