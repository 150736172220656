import Button from 'app/components/Button';
import Input from 'app/components/Input';
import DeleteIcon from 'assets/icons/delete.svg';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Specification } from 'types/entities';

interface SpecificationsTableProps {
  data: Specification[];
  register: any;
  removeSpecification: (itemIndex: number) => void;
  index: number;
}

const SpecificationsTable: React.FC<SpecificationsTableProps> = ({
  data,
  register,
  removeSpecification,
  index,
}) => {
  const { t } = useTranslation();
  const visibleSpecifications = data.filter(spec => !spec.is_deleted);
  return (
    <div className="my-10 flex w-full justify-center overflow-x-auto">
      {visibleSpecifications.length === 0 ? (
        <div className="py-10 text-center text-gray-500" />
      ) : (
        <div className="w-[95%] max-w-4xl">
          <table className="min-w-full border-collapse text-center">
            <thead>
              <tr className="font-airbnb text-[12px] font-semibold">
                <th className="border-b-0 px-4 py-2">
                  {t('ProductListing.name')}
                </th>
                <th className="border-b-0 px-4 py-2">
                  {t('ProductListing.value')}
                </th>
                <th className="border-b-0 px-4 py-2">
                  {t('ProductListing.product_display')}
                </th>
                <th className="border-b-0 p-2">
                  {t('ProductListing.description_note')}
                </th>
                <th className="border-b-0 px-4 py-2">
                  {t('ProductListing.action')}
                </th>
              </tr>
            </thead>
            <tbody className="bg-light-gray-300 divide-y divide-gray-200 text-[12px] font-medium">
              {visibleSpecifications.map((item, tableIndex) => (
                <tr
                  key={item.id || tableIndex}
                  className="border-t border-gray-200 bg-gray-100"
                >
                  <td
                    className={clsx('px-8 py-1', {
                      'rounded-tl-lg rounded-bl-lg': true,
                    })}
                  >
                    {item.name}
                  </td>
                  <td className="px-8 py-1">{item.value}</td>
                  <td className="px-8 py-1">
                    <Input
                      type="checkbox"
                      placeholder="name"
                      containerClassName="relative flex items-center"
                      inputClassName="focus:outline-none w-16 border border-gray-200 font-normal"
                      alt="specifications"
                      height="16px"
                      {...register(
                        `product_details[${index}].specifications[${tableIndex}].display_on_product`,
                      )}
                    />
                  </td>
                  <td className="px-8 py-1">
                    <Input
                      height="30px"
                      placeholder="Description"
                      containerClassName="relative mt-2 flex items-center w-300"
                      inputClassName="focus:outline-none text-[14px]"
                      {...register(
                        `product_details[${index}].specifications[${tableIndex}].description`,
                      )}
                    />
                  </td>
                  <td className="rounded-r-lg p-1">
                    <Button
                      htmlType="button"
                      onClick={() => removeSpecification(tableIndex)}
                    >
                      <img
                        src={DeleteIcon}
                        alt={t('delete')}
                        width={20}
                        height={20}
                      />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default SpecificationsTable;
