import type { User } from 'firebase/auth';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { auth } from '../utils/firebaseAuthConfig';

interface AuthContextType {
  user: User | null;
  accessToken: string | null;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<any>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const authToken = onAuthStateChanged(auth, async userInfo => {
      if (userInfo) {
        setUser(userInfo);
        const token = await userInfo.getIdToken();
        setAccessToken(token);
        localStorage.setItem('userToken', token);
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
      } else {
        setUser(null);
        setAccessToken(null);
        localStorage.removeItem('userToken');
        localStorage.removeItem('userInfo');
      }
      setLoading(false);
    });

    const tokenFromLocalStorage = localStorage.getItem('userToken');
    const userFromLocalStorage = localStorage.getItem('userInfo');
    if (tokenFromLocalStorage && userFromLocalStorage) {
      setAccessToken(tokenFromLocalStorage);
      setUser(JSON.parse(userFromLocalStorage));
      setLoading(false);
    } else {
      setLoading(false);
    }

    return () => authToken();
  }, []);

  const logout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem('userToken');
      localStorage.removeItem('userInfo');
    } catch (error) {
      alert('Logout failed. Please try again');
    }
  };

  const value = useMemo(
    () => ({
      user,
      accessToken,
      logout,
    }),
    [user, accessToken],
  );

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
export const getTokenFromLocalStorage = () => {
  return localStorage.getItem('userToken');
};
