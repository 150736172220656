import { zodResolver } from '@hookform/resolvers/zod';
import { Form as AntdForm } from 'antd';
import Button from 'app/components/Button';
import ConfirmationModal from 'app/components/ConfirmationModal';
import ImageUploader from 'app/components/ImageUploader';
import MultiSelect from 'app/components/MultiSelect';
import Tabs, { type Tab } from 'app/components/Tabs';
import SuccessIcon from 'assets/icons/sucessIcon.svg';
import {
  useGetProductTypes,
  usePublishListing,
  useUpdateListing,
} from 'hooks/queries';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import type { ProductTypeFormData } from 'types/entities';
import type { z } from 'zod';

import { ProductTypeFormScheme } from '../../../constants/validationSchema';
import CategoryAndProductType from './CategoryAndProductType';
import GeneralInformation from './GeneralInformation';
import PricingAndStock from './PricingAndStock';

type ProductListingFormType = z.infer<typeof ProductTypeFormScheme>;

const initialValues: ProductListingFormType = {
  title: '',
  description: '',
  category_uuid: '',
  images: [],
  product_details: [
    {
      product_type_uuid: '',
      pricing: {
        base_price: 0,
      },
      attributes: [],
      variants: [],
      addons: [],
      specifications: [],
    },
  ],
  status: '',
};

interface ProductListingProps {
  updateData?: ProductTypeFormData;
}

const ProductListing: React.FC<ProductListingProps> = ({ updateData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate: publishListing } = usePublishListing();
  const { mutate: updateListing } = useUpdateListing();
  const form = useForm<ProductListingFormType>({
    mode: 'onChange',
    resolver: zodResolver(ProductTypeFormScheme),
    defaultValues: initialValues,
  });

  const {
    formState: { errors },
    setValue,
    control,
    handleSubmit,
    watch,
    clearErrors,
    reset,
    register,
  } = form;

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [modalMessage, setModalMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tabIndex, setIndex] = useState<number>(0);
  const [tabs, setTabs] = useState<Tab[]>([]);

  const {
    data: ProductTypes,
    isLoading: isProductTypeLoading,
    error: productTypeError,
    isSuccess: isProductTypesSuccess,
  } = useGetProductTypes({});

  useEffect(() => {
    if (updateData) {
      const updatedProductDetails = updateData.product_details.map(detail => ({
        ...detail,
        product_type_uuid: detail.product_type?.uuid || '',
      }));

      reset({
        ...updateData,
        category_uuid: updateData.category?.uuid || '',
        product_details: updatedProductDetails,
      });

      const data = updatedProductDetails.map(
        detail => detail.product_type_uuid,
      );
      setSelectedItems(data);
    }
  }, [updateData, reset]);

  useEffect(() => {
    const updatedTabs = selectedItems.map((item, index) => {
      setIndex(index);
      const itemLabel =
        ProductTypes?.data.find((p: { uuid: string }) => p?.uuid === item)
          ?.title || item;
      return {
        id: `tab-${index}`,
        label: itemLabel,
        content: (
          <PricingAndStock
            key={item}
            register={register}
            errors={errors}
            setValue={setValue}
            watch={watch}
            index={index}
          />
        ),
      };
    });
    setTabs(updatedTabs);
  }, [selectedItems, ProductTypes?.data, errors]);

  const onSubmit: SubmitHandler<ProductListingFormType> = (data, e: any) => {
    const buttonId = e?.nativeEvent?.submitter?.id;
    const status = buttonId === 'draft' ? 'DRAFT' : 'IN_REVIEW';
    const payload = {
      basic_info: {
        title: data.title,
        description: data.description,
        category_uuid: data.category_uuid,
        images: data.images,
      },
      product_details: data.product_details.map((detail: any) => ({
        ...detail,
        product_type_uuid: detail.product_type_uuid || '',
      })),
      status,
    };

    const onSuccess = () => {
      setModalMessage(
        updateData
          ? t('ProductListing.update_message')
          : t('ProductListing.submit_message'),
      );
      setIsModalOpen(true);
    };

    if (updateData?.uuid) {
      updateListing({ payload, uuid: updateData.uuid }, { onSuccess });
    } else {
      publishListing(payload, { onSuccess });
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    navigate('/product-listings');
  };

  const multiOptions = useMemo(() => {
    if (isProductTypesSuccess && ProductTypes) {
      return Array.isArray(ProductTypes.data)
        ? ProductTypes.data.map((product: any) => ({
            label: product.title,
            value: product.uuid,
          }))
        : [];
    }
    return [];
  }, [ProductTypes, isProductTypesSuccess]);
  const handleMultiSelectChange = (updatedItems: string[]) => {
    setSelectedItems(updatedItems);
    setValue(
      'product_details',
      updatedItems.map(product_type_uuid => ({
        product_type_uuid,
        pricing: {
          base_price: 0,
        },
        attributes: [],
        variants: [],
        addons: [],
        specifications: [],
      })),
    );
    updatedItems.forEach((_, index) => {
      clearErrors(`product_details.${index}.product_type_uuid`);
    });
  };

  if (isProductTypeLoading) {
    return <p className="p-10 text-red-600">{t('loading')}</p>;
  }

  if (productTypeError) {
    return <p className="text-red-600">{t('error_loading_data')}</p>;
  }

  return (
    <>
      <div className="rounded-xl border border-gray-300 bg-white p-7 shadow-md">
        <div className="flex flex-row justify-between pb-5">
          <div className="flex flex-col">
            <div className="text-lg font-bold">
              {t('addProduct.productInformation')}
            </div>
            <div className="flex flex-row">
              <Link to="/dashboard">{t('addProduct.dashboard')}</Link>
              <span className="px-2">/</span>
              <div className="text-blue-800">
                <Link to="/add-product">{t('addProduct.addProduct')}</Link>
              </div>
            </div>
          </div>
        </div>
        <FormProvider {...form}>
          <AntdForm
            layout="vertical"
            onFinish={handleSubmit(onSubmit)}
            className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3"
          >
            <div className="w-full md:col-span-2 lg:col-span-2">
              <GeneralInformation
                control={control}
                errors={errors}
                register={register}
              />
              <div className="h-auto w-full">
                <CategoryAndProductType
                  value={watch('category_uuid')}
                  setValue={setValue}
                  errors={errors}
                  clearErrors={clearErrors}
                  selected={selectedItems}
                  onChange={setSelectedItems}
                  register={register}
                />
                <div className="pt-4">
                  <MultiSelect
                    id="product_type_uuid"
                    options={multiOptions}
                    label={t('ProductListing.select_product_type')}
                    placeholder={t('ProductListing.select_product_type')}
                    onChange={handleMultiSelectChange}
                    selectedItems={selectedItems}
                  />
                  {errors?.product_details?.[tabIndex]?.product_type_uuid && (
                    <p className="text-red-500">
                      {
                        // errors?.product_details?.[tabIndex]?.product_type_uuid
                        //   .message
                      }
                    </p>
                  )}
                </div>
              </div>
              <div className="mb-6 size-auto">
                <div className="flex h-auto w-full justify-center">
                  <div className="my-14 w-full">
                    <Tabs
                      tabs={tabs}
                      activeTabIndex={activeTabIndex}
                      onTabChange={setActiveTabIndex}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full pt-6 md:col-span-1 lg:col-span-1">
              <ImageUploader
                name="product_images"
                label={t('ProductListing.upload_product_image')}
                updatedImage={updateData?.images}
              />
            </div>

            <div className="mr-10 mt-2 flex justify-end space-x-7 md:col-span-2 lg:col-span-2">
              <Button htmlType="reset" id="draft">
                {t('ProductListing.save_drafts')}
              </Button>

              <Button
                htmlType="button"
                className="hover:bg-light-gray-500 h-[42px] min-w-[140px] rounded-xl bg-dark-blue px-5 text-white hover:text-black lg:min-w-[153px]"
              >
                {updateData
                  ? t('ProductListing.update')
                  : t('ProductListing.submit')}
              </Button>
            </div>
          </AntdForm>
        </FormProvider>
      </div>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        width="684px"
        height="269px"
        headerTitle="Confirmation"
        containerClassName="z-20 "
        headerClassName="rounded-t-2xl"
        overFlow="overflow-y-auto"
      >
        <div className="mt-5 flex flex-col items-center justify-center p-4 text-center font-airbnb">
          <img src={SuccessIcon} alt="success" className="justify-center" />
          <p className="text-dark-green mt-3 text-[20px]">{modalMessage}</p>
        </div>
      </ConfirmationModal>
    </>
  );
};

export default ProductListing;
