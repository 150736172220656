import Input from 'app/components/Input';
import SearchInput from 'app/components/SearchInput';
import Uploader from 'app/components/Uploader';
import { useUploadImage } from 'hooks/queries';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AddIcon from '../../../assets/icons/addIcon2.svg';
import Searchicon from '../../../assets/icons/searchIcon.svg';
import Table from './AddOnsAndSpecficationTable';

interface AddOnsProps {
  register: any;
  watch: any;
  setValue: any;
  index: number;
}

const AddOns: React.FC<AddOnsProps> = ({
  register,
  watch,
  setValue,
  index,
}) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const [searchValue, setSearchValue] = useState('');
  const addOnsData = Array.isArray(watch(`product_details[${index}].addOns`))
    ? watch(`product_details[${index}].addOns`)
    : [];

  const { mutate: UploadImage, data } = useUploadImage();
  const [UploadedImage, setUploadedImage] = useState<File>();

  const addAddOn = () => {
    const newAddOn = {
      name: getValues(`product_details[${index}].addOns.name`) || '',
      price: getValues(`product_details[${index}].addOns.price`) || '',
      description:
        getValues(`product_details[${index}].addOns.description`) || '',
      display_on_product: getValues(
        `product_details[${index}].addOns.display_on_product`,
      ),
      image: data?.url,
    };
    const filterAddOns = [...addOnsData, newAddOn];
    setValue(`product_details[${index}].addOns`, filterAddOns);
  };

  const removeAddOn = (itemIndex: number) => {
    const updatedData = addOnsData.filter(
      (_: any, i: number) => i !== itemIndex,
    );
    setValue(`product_details[${index}].addOns`, updatedData);
  };
  const filteredAddOns = addOnsData.filter((addOn: any) =>
    addOn.name.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const handleImageUpload = (file: File) => {
    const formData = new FormData();
    setUploadedImage(file);
    formData.append('file', file);
    UploadImage(formData);
  };

  return (
    <div className="mt-5 h-auto w-full">
      <div className="flex">
        <div className="mt-2 flex h-[55px] w-1/5 rounded-r-xl bg-light-gray">
          <h1 className="items-center p-4 font-airbnb text-[16px] font-bold">
            {t('ProductListing.add_ons')}
          </h1>
        </div>
        <div className="ml-10 p-4">
          <SearchInput
            value={searchValue}
            onChange={setSearchValue}
            placeholder={t('ProductListing.placeholder-add-ons')}
            iconPosition="right"
            icon={Searchicon}
            containerClassName="bg-white border border-light-gray rounded-lg shadow-sm w-[380px] "
            inputClassName=" py-2 pl-10 pr-3 text-sm cursor-pointer w-full bg-light-gray"
          />
        </div>
      </div>
      {/* Input Data */}
      <div className="flex">
        <div className="ml-3 mt-5 flex w-auto">
          <Uploader
            onImageUpload={handleImageUpload}
            accept="image/*"
            width={160}
            height={50}
            MainContainerHeight="81px"
            MainContainerWidth="146px"
            AddIconWidth={30}
            AddIconHeight={30}
            AddIconClassName=" left-16 top-0 justify-center"
            borderColor="border  border-gray-300"
          />
        </div>
        <div className="ml-5 mt-8 w-3/4">
          <div className="flex w-full justify-between">
            <div className="mb-4">
              <label
                htmlFor={t('ProductListing.name')}
                className="p-2 font-airbnb text-[14px] font-medium"
              >
                {t('ProductListing.name')}
              </label>
              <Input
                placeholder="Name"
                containerClassName="relative flex items-center"
                inputClassName="focus:outline-none w-[220px] border border-gray-200 shadow-sm"
                height="36px"
                alt="name"
                {...register(`product_details[${index}].addOns.name`)}
              />
            </div>
            <div className="mb-4 ml-2 w-[110px]">
              <label
                htmlFor={t('ProductListing.price')}
                className="p-2 font-airbnb text-[14px] font-medium"
              >
                {t('ProductListing.price')}
              </label>
              <Input
                placeholder="Price:"
                containerClassName="relative flex items-center"
                inputClassName="focus:outline-none border border-gray-200 shadow-sm"
                height="36px"
                alt="AddPrice:"
                {...register(`product_details[${index}].addOns.price`)}
              />
            </div>
            <div className="mb-4 ml-2 w-[469px]">
              <label
                htmlFor={t('ProductListing.description_note')}
                className="p-2 font-airbnb text-[14px] font-medium"
              >
                {t('ProductListing.description_note')}
              </label>
              <Input
                placeholder="Description"
                containerClassName="relative flex items-center"
                inputClassName="focus:outline-none border border-gray-200 shadow-sm"
                height="36px"
                alt="AddOnsDescription"
                {...register(`product_details[${index}].addOns.description`)}
              />
            </div>
            <div className="ml-3 mt-6">
              <button
                type="button"
                onClick={addAddOn}
                onKeyDown={e => {
                  if (e.key === 'Enter') addAddOn();
                }}
                aria-label={t('ProductListing.add')}
                className="flex items-center justify-center rounded bg-white"
              >
                <img
                  src={AddIcon}
                  alt={t('ProductListing.add')}
                  width={40}
                  height={40}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Table */}
      {Array.isArray(addOnsData) ? (
        <Table
          data={filteredAddOns}
          type="addOns"
          register={register}
          onDeleteRow={removeAddOn}
          index={index}
          UploadedImage={UploadedImage}
        />
      ) : null}
    </div>
  );
};

export default AddOns;
