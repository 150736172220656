import Button from 'app/components/Button';
import Input from 'app/components/Input';
import Uploader from 'app/components/Uploader';
import { useUploadImage } from 'hooks/queries';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ProductVariant } from 'types/entities';

import DeleteIcon from '../../../assets/icons/deleteIcon.svg';

interface VariantsTableProps {
  variants: ProductVariant[];
  onRemoveVariant: (id: string) => void;
  register: any;
  setValue: any;
  index: any;
}

const VariantsTable: React.FC<VariantsTableProps> = ({
  variants,
  // onRemoveVariant,
  register,
  index,
  setValue,
}) => {
  const { t } = useTranslation();
  const { mutate: UploadImage } = useUploadImage();

  const handleImageUpload = (file: File, variantIndex: number) => {
    const formData = new FormData();
    formData.append('file', file);
    UploadImage(formData, {
      onSuccess: (data: any) => {
        setValue(
          `product_details[${index}].variant[${variantIndex}].image`,
          data.url,
        );
      },
    });
  };

  return (
    <div className="my-2 flex w-full justify-center overflow-x-auto">
      {variants.length === 0 ? (
        <div className=" text-center text-gray-500" />
      ) : (
        <div className="mr-10 w-full max-w-4xl">
          <table className="min-w-full border-collapse text-center">
            <thead>
              <tr className="bg-white text-center">
                <th className="py-2 text-[12px] font-normal">
                  {t('ProductListing.image')}
                </th>
                <th className="py-2 text-left text-[12px] font-normal">
                  {t('ProductListing.variant_ID')}
                </th>
                <th className="py-2 text-left text-[12px] font-normal">
                  {t('ProductListing.attributes')}
                </th>
                <th className="py-2 text-left text-[12px] font-normal">
                  {t('ProductListing.price')}
                </th>
                <th className="py-2 text-left text-[12px] font-medium">
                  {t('ProductListing.in_stock')}
                </th>
                <th className="py-2 text-left text-[12px] font-normal">
                  {t('ProductListing.product_display')}
                </th>
                <th className="py-2 text-left text-[12px] font-normal">
                  {t('ProductListing.description_note')}
                </th>
                <th className="px-4 py-2 text-left text-[12px] font-normal">
                  {t('ProductListing.action')}
                </th>
              </tr>
            </thead>
            <tbody className="bg-gray-100">
              {variants.map((variant, tableIndex) => (
                <tr
                  key={variant.id}
                  className="bg-light-gray-500 mb-4 divide-y rounded-3xl rounded-es-lg rounded-ss-lg border border-dark-gray/30 px-4 py-2"
                >
                  <td
                    className="rounded-es-lg rounded-ss-lg px-4 py-2"
                    aria-label="uploader"
                  >
                    <Uploader
                      onImageUpload={file =>
                        handleImageUpload(file, tableIndex)
                      }
                      borderColor="border  border-gray-300"
                      AddIconClassName="flex justify-center"
                    />
                  </td>
                  <td className="px-2">#{variant.id}</td>
                  <td>
                    <Input
                      height="23px"
                      containerClassName="relative mt-2 flex items-center w-[57px]"
                      inputClassName="focus:outline-none"
                      type="text"
                      // defaultValue={variant.price}
                      {...register(
                        `product_details[${index}].variant[${tableIndex}].price`,
                      )}
                    />
                  </td>
                  <td className="px-8 py-2">
                    <Input
                      type="checkbox"
                      height="16px"
                      containerClassName="relative mt-2 flex items-center"
                      inputClassName="focus:outline-none w-[16]"
                      {...register(
                        `product_details[${index}].variant[${tableIndex}].isStock`,
                      )}
                    />
                  </td>
                  <td className="px-8 py-2">
                    <Input
                      type="checkbox"
                      height="16px"
                      containerClassName="relative mt-2 flex items-center"
                      inputClassName="focus:outline-none w-[16]"
                      {...register(
                        `product_details[${index}].variant[${tableIndex}].is_available_on_product`,
                      )}
                    />
                  </td>
                  <td>
                    <Input
                      type="text"
                      placeholder=""
                      height="30px"
                      containerClassName="relative mt-2 flex items-center w-[183px]"
                      inputClassName="focus:outline-none"
                      {...register(
                        `product_details[${index}].variant[${tableIndex}].description`,
                      )}
                    />
                  </td>
                  <td className="rounded-br-lg rounded-se-lg ">
                    <Button
                      htmlType="button"
                      // onClick={() => onRemoveVariant(variant.id)}
                      className="mt-3 text-red-500 hover:text-red-700"
                    >
                      <img
                        src={DeleteIcon}
                        alt={t('ProductListing.delete')}
                        width={20}
                        height={20}
                      />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default VariantsTable;
