import React from 'react';

import { NavBar } from '../Header';
import { SideBar } from '../SideBar';

export const Layout = ({ children }: any) => {
  return (
    <div className="flex min-h-screen flex-col gap-10 bg-slate-100 p-5 md:flex-row">
      <SideBar />
      <div className="ml-72 flex grow flex-col">
        <div className="sticky top-5 z-40">
          <NavBar />
        </div>
        <div className="flex-1 overflow-auto py-3">{children}</div>
      </div>
    </div>
  );
};
