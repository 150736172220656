import CategoryIcon from 'assets/icons/categories.svg';
import CategoryDarkIcon from 'assets/icons/categoriesDark.svg';
import DashboardIcon from 'assets/icons/dashboard.svg';
import DashboardDarkIcon from 'assets/icons/dashboardDark.svg';
import DocumentTypeDarkIcon from 'assets/icons/documentDarkIcon.svg';
import DocumentTypeIcon from 'assets/icons/documentIcon.svg';
import Logo from 'assets/icons/logo.svg';
import OrderIcon from 'assets/icons/myOrders.svg';
import OrderDarkIcon from 'assets/icons/myOrdersdark.svg';
import ReceiptDarkIcon from 'assets/icons/productDark.svg';
import ProductListingIcon from 'assets/icons/productListing.svg';
import ProductListingDarkIcon from 'assets/icons/productListingDark.svg';
import ReceiptIcon from 'assets/icons/receipt.svg';
import SettingIcon from 'assets/icons/setting.svg';
import SettingDarkIcon from 'assets/icons/settingDark.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

interface SidebarItemProps {
  to: string;
  icon: string;
  selectedIcon: string;
  label: string;
  isSelected: boolean;
}

const SidebarItem = ({
  to,
  icon,
  selectedIcon,
  label,
  isSelected,
}: SidebarItemProps) => (
  <li>
    <Link
      to={to}
      className={`group flex items-center rounded-lg p-2 ${
        isSelected
          ? 'bg-white text-black'
          : 'text-gray-900 hover:bg-[rgba(226,226,226,0.20)] hover:opacity-100 dark:text-white dark:hover:bg-[rgba(226,226,226,0.20)]'
      }`}
    >
      <img src={isSelected ? selectedIcon : icon} alt={label} />
      <span className={`ms-3 ${isSelected ? 'text-black' : ''}`}>{label}</span>
    </Link>
  </li>
);

export function SideBar() {
  const { t } = useTranslation();
  const location = useLocation();
  const currentPath = location.pathname;

  const links = [
    {
      to: '/dashboard',
      icon: DashboardIcon,
      selectedIcon: DashboardDarkIcon,
      label: t('sideBar.dashboard'),
    },
    {
      to: '/orders',
      icon: OrderIcon,
      selectedIcon: OrderDarkIcon,
      label: t('sideBar.orders'),
    },
    {
      to: '/product-type',
      icon: CategoryIcon,
      selectedIcon: CategoryDarkIcon,
      label: t('sideBar.productType'),
    },
    // {
    //   to: '/add-product',
    //   icon: ReceiptIcon,
    //   selectedIcon: ReceiptDarkIcon,
    //   label: t('sideBar.addProduct'),
    // },
    {
      to: '/product-listings',
      icon: ProductListingIcon,
      selectedIcon: ProductListingDarkIcon,
      label: t('sideBar.productListing'),
    },
    {
      to: '/categories',
      icon: CategoryIcon,
      selectedIcon: CategoryDarkIcon,
      label: t('sideBar.categories'),
    },
    {
      to: '/document-type',
      icon: DocumentTypeIcon,
      selectedIcon: DocumentTypeDarkIcon,
      label: t('sideBar.documentType'),
    },
  ];

  const accountLinks = [
    {
      to: '/settings',
      icon: SettingIcon,
      selectedIcon: SettingDarkIcon,
      label: 'Settings',
    },
    {
      to: '/',
      icon: ReceiptIcon,
      selectedIcon: ReceiptDarkIcon,
      label: t('sideBar.logout'),
    },
  ];

  return (
    <div className="fixed h-[calc(100vh-2.5rem)] w-[254px]">
      <aside
        id="default-sidebar"
        className="h-full rounded-2xl bg-dark-blue px-3 py-4 sm:translate-x-0"
        aria-label="Sidebar"
      >
        <div className="ml-8 flex h-16 w-32 items-center ps-2.5">
          <Link to="/dashboard">
            <img src={Logo} alt="logo" />
          </Link>{' '}
        </div>
        <hr className="mb-8" />
        <ul className="max-h-[calc(100vh-170px)] space-y-2 overflow-auto font-medium transition-transform">
          {links.map(link => (
            <SidebarItem
              key={link.to}
              to={link.to}
              icon={link.icon}
              selectedIcon={link.selectedIcon}
              label={link.label}
              isSelected={currentPath === link.to}
            />
          ))}
          <div className="font-bold text-white">Account pages</div>
          {accountLinks.map(link => (
            <SidebarItem
              key={link.to}
              to={link.to}
              icon={link.icon}
              selectedIcon={link.selectedIcon}
              label={link.label}
              isSelected={currentPath === link.to}
            />
          ))}
        </ul>
      </aside>
    </div>
  );
}
