import Tabs from 'app/components/Tabs';
import { useEffect, useState } from 'react';
import type { ProductVariant, Specification } from 'types/entities';

import AddToCart from './AddToCart';
import ProductDetailCard from './ProductDetailCard';
import ProductDetailTable from './ProductDetailTable';

interface DetailItem {
  uuid: string;
  variants: string;
  value: string;
}

const ProductDetails: React.FC<any> = ({ product, tabs }) => {
  const [attributes, setAttributes] = useState<DetailItem[]>([]);
  const [specifications, setSpecifications] = useState<DetailItem[]>([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    const productDetails = product?.product_details?.[activeTabIndex];
    if (productDetails) {
      const attributeMap: Record<string, string[]> = {};
      productDetails.variants?.forEach((attr: ProductVariant) => {
        const attrName = attr.name;
        const attrValues = attr.variants_values || [];

        if (attrName) {
          if (!attributeMap[attrName]) {
            attributeMap[attrName] = [];
          }

          attrValues.forEach((value: any) => {
            if (!value.is_deleted) {
              attributeMap[attrName]?.push(value.value);
            }
          });
        }
      });
      const mappedAttributes = Object.entries(attributeMap).map(
        ([name, values], index) => ({
          uuid: `uuid-${index}`,
          variants: name,
          value: values.join(' '),
        }),
      );

      const specificationMap: Record<string, string> = {};
      productDetails.specifications?.forEach((spec: Specification) => {
        const specName = spec.name;
        const specValue = spec.value;

        if (specName) {
          specificationMap[specName] = specValue;
        }
      });
      const mappedSpecifications = Object.entries(specificationMap).map(
        ([name, value], index) => ({
          uuid: `uuid-${index}`,
          variants: name,
          value,
        }),
      );

      setAttributes(mappedAttributes);
      setSpecifications(mappedSpecifications);
    }
  }, [activeTabIndex, product?.product_details]);

  return (
    <div className="p-4 sm:p-6 lg:p-8">
      <div className="flex flex-col justify-between lg:flex-row">
        <div className="w-full lg:w-3/5">
          <ProductDetailCard item={product} />
        </div>
        <div className="mt-4 w-full lg:w-2/5">
          <div>
            {tabs?.length > 1 && (
              <div className="mb-4">
                <Tabs
                  tabs={tabs}
                  onTabChange={setActiveTabIndex}
                  activeTabIndex={activeTabIndex}
                />
              </div>
            )}

            <div className="mx-2 my-4 rounded-3xl border bg-white shadow-md sm:p-6">
              <AddToCart products={product} activeTabIndex={activeTabIndex} />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 flex flex-col gap-8 sm:mt-8 lg:mt-10 lg:flex-row">
        <div className="w-full lg:w-1/2">
          {attributes.length > 0 ? (
            <>
              <div className="mb-2 text-lg font-bold text-stone-800 underline sm:mb-4">
                Attributes
              </div>
              <ProductDetailTable item={attributes} />
            </>
          ) : (
            <div className="text-gray-500">No Attributes Available</div>
          )}
        </div>

        <div className="w-full lg:w-1/2">
          {specifications.length > 0 ? (
            <>
              <div className="mb-2 text-lg font-bold text-stone-800 underline sm:mb-4">
                Specification
              </div>
              <ProductDetailTable item={specifications} />
            </>
          ) : (
            <div className="text-gray-500">No Specifications Available</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
