'use client';
import PlaceholderImage from 'assets/img/placeholderImage.png';
import dayjs, { type Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import type {
  Addon,
  ProductTypeFormData,
  ProductVariant,
  VariantValue,
} from 'types/entities';

import DatePickerCart from './DatePickerCart';

interface AddToCartProps {
  products: ProductTypeFormData;
  activeTabIndex: number;
}

interface ProductDetail {
  pricing?: {
    base_price: number;
  };
  variants?: ProductVariant[];
  addons?: Addon[];
  availability?: { start_datetime: string; end_datetime: string }[];
}

interface AddOnState {
  selected: boolean;
  quantity: number;
}

const AddToCart: React.FC<AddToCartProps> = ({ products, activeTabIndex }) => {
  const [addOns] = useState<Record<string, AddOnState>>({});
  const [productDetail, setProductDetail] = useState<ProductDetail>({});
  const [selectedVariants, setSelectedVariants] = useState<
    Record<string, VariantValue | null>
  >({});
  const [isCheckInPickerOpen, setIsCheckInPickerOpen] = useState(false);
  const [isCheckOutPickerOpen, setIsCheckOutPickerOpen] = useState(false);
  const [checkInDate, setCheckInDate] = useState<string | null>(null);
  const [checkOutDate, setCheckOutDate] = useState<string | null>(null);
  const formatPrice = (price: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(price);
  };
  useEffect(() => {
    setProductDetail(products?.product_details?.[activeTabIndex] || {});
    setSelectedVariants({});
  }, [activeTabIndex, products?.product_details]);

  const calculateTotal = (): number => {
    const basePrice = productDetail.pricing?.base_price ?? 0;
    const variantPrice = Object.values(selectedVariants).reduce(
      (total, variantValue) => total + (variantValue?.price ?? 0),
      0,
    );
    const addOnsTotal =
      productDetail.addons
        ?.filter(addon => addOns[addon.uuid]?.selected)
        .reduce(
          (total, addon) =>
            total + addon.price * (addOns[addon.uuid]?.quantity ?? 0),
          0,
        ) ?? 0;

    return basePrice + variantPrice + addOnsTotal;
  };

  const getImageSrc = (src?: string) => src || PlaceholderImage;

  const availabilityDates = productDetail?.availability || [];
  const disabledDates = (date: Dayjs) => {
    const today = dayjs().startOf('day');
    return date.isBefore(today);
  };

  const disabledDatesCheckIn = (current: any) => {
    if (!checkInDate) return false;
    return current.isBefore(checkInDate, 'day');
  };
  const handleVariantClick = (
    variantName: string | undefined,
    variantValue: VariantValue,
  ) => {
    if (variantName) {
      setSelectedVariants(prevSelectedVariants => {
        const isAlreadySelected =
          prevSelectedVariants[variantName]?.uuid === variantValue.uuid;

        if (isAlreadySelected) {
          const updatedVariants = { ...prevSelectedVariants };
          delete updatedVariants[variantName];
          return updatedVariants;
        }

        return {
          ...prevSelectedVariants,
          [variantName]: variantValue,
        };
      });
    }
  };
  const basePrice = productDetail.pricing?.base_price ?? 0;
  return (
    <>
      <div className="flex w-full items-center justify-between">
        <h2 className="mt-8 text-lg font-bold text-stone-800"> Price</h2>
        <h2 className="mt-8 text-lg font-bold text-stone-800">
          {formatPrice(basePrice)}
          <span className="text-[12px] font-normal">
            /{products?.availability_type}
          </span>
        </h2>
      </div>

      {/* Date and Time */}

      <DatePickerCart
        checkInDate={checkInDate}
        checkOutDate={checkOutDate}
        setCheckInDate={setCheckInDate}
        setCheckOutDate={setCheckOutDate}
        isCheckInPickerOpen={isCheckInPickerOpen}
        setIsCheckInPickerOpen={setIsCheckInPickerOpen}
        isCheckOutPickerOpen={isCheckOutPickerOpen}
        setIsCheckOutPickerOpen={setIsCheckOutPickerOpen}
        availabilityDates={availabilityDates}
        disabledDates={disabledDates}
        disabledDatesCheckIn={disabledDatesCheckIn}
      />

      <hr className="mt-8 w-full" />
      {productDetail.variants?.length ? (
        <>
          <div className="mt-4 flex items-center justify-between text-lg font-medium text-stone-800">
            Variations
          </div>
          <h4 className="text-[12px] font-normal text-gray-400">
            Total Options: {productDetail.variants?.length}
          </h4>
          {productDetail.variants.map((variant: ProductVariant) => (
            <>
              <div key={variant.id} className="mx-1 my-2">
                <div className="mb-2 text-base font-semibold">
                  {' '}
                  {variant.name}
                </div>
                <div className="-m-2 flex flex-wrap">
                  {variant.variants_values?.map(
                    (variantValue: VariantValue) => (
                      <div key={variantValue.uuid} className="w-1/2 p-2">
                        <div className="flex flex-row items-start rounded-lg p-2">
                          <div
                            className={`ml-2 flex w-full cursor-pointer flex-col justify-between rounded-lg border border-gray-400 font-semibold shadow-md hover:bg-dark-blue
                              hover:text-white ${
                                selectedVariants[variant.name ?? '']?.uuid ===
                                variantValue.uuid
                                  ? 'bg-dark-blue text-white'
                                  : 'bg-white'
                              }`}
                            onClick={() =>
                              handleVariantClick(variant.name, variantValue)
                            }
                          >
                            <div className="flex flex-row items-center justify-between gap-4 p-2">
                              <p className="text-[16px]">
                                {variantValue.value}
                              </p>
                              <p className="text-[16px] ">
                                {formatPrice(variantValue.price)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ),
                  )}
                </div>
              </div>
              <hr className="my-4 w-full" />
            </>
          ))}
        </>
      ) : (
        <div />
      )}

      {productDetail.addons?.length ? (
        <>
          <div className="mt-4 flex items-center justify-between text-lg font-medium text-stone-800">
            Add Ons
          </div>

          {productDetail.addons.map((addon: Addon) => (
            <>
              <h4 className="text-base font-normal text-gray-400">
                Total Options: {productDetail.addons?.length}
              </h4>
              <div key={addon.uuid} className="mx-1 my-2">
                <div className="flex flex-row items-start rounded-lg p-2">
                  <div className="h-[80px] w-[116px] overflow-hidden rounded border object-cover shadow-sm ">
                    <img
                      src={getImageSrc(addon.image)}
                      alt="add Icon"
                      className=" object-cover"
                    />
                  </div>
                  <div className="ml-2 flex w-full flex-col justify-between">
                    <p className="text-[14px] font-normal text-gray-700">
                      {addon.name.charAt(0).toUpperCase() + addon.name.slice(1)}
                    </p>
                    <div className="flex flex-row items-center justify-between">
                      <p className="text-[14px] font-normal text-gray-700">
                        {formatPrice(addon.price)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <hr className="mt-5" />
            </>
          ))}
        </>
      ) : (
        <div className="h-30px" />
      )}

      <div className="py-4 text-base font-bold text-black ">
        <div className="flex justify-between">
          <p>Item subtotal</p>
          <p>
            {formatPrice(
              Object.values(selectedVariants).reduce(
                (total, variantValue) => total + (variantValue?.price ?? 0),
                0,
              ),
            )}
          </p>
        </div>
        <div className="flex justify-between">
          <p>Add ons total</p>
          <p>
            {formatPrice(
              productDetail.addons
                ?.filter(addon => addOns[addon.uuid]?.selected)
                .reduce(
                  (total, addon) =>
                    total + addon.price * (addOns[addon.uuid]?.quantity ?? 0),
                  0,
                ) ?? 0,
            )}
          </p>
        </div>
        <div className="flex justify-between">
          <p>Total</p>
          <p>{formatPrice(calculateTotal())}</p>
        </div>
      </div>
    </>
  );
};

export default AddToCart;
