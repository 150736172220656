import Input from 'app/components/Input';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { ProductVariant } from 'types/entities';

import AddIcon from '../../../assets/icons/addIcon2.svg';
import AttributesTable from './AttributesTable';
import VariantsTable from './VariantsTable';

interface AttributesAndVariantsProps {
  register: ReturnType<typeof useFormContext>['register'];
  setValue: ReturnType<typeof useFormContext>['setValue'];
  watch: ReturnType<typeof useFormContext>['watch'];
  index: number;
  errors?: any;
}

export const AttributesAndVariants: React.FC<AttributesAndVariantsProps> = ({
  register,
  watch,
  setValue,
  index,
}) => {
  const { t } = useTranslation();
  const { getValues, setValue: formSetValue } = useFormContext();
  const [requiredErrorMessage, setRequiredErrorMessage] = useState<string>('');
  const attributes = watch(`product_details[${index}].attributes`) || [];
  const variants = watch(`product_details[${index}].variant`) || [];

  const addAttribute = () => {
    const name = getValues(`product_details[${index}].attributes.name`) || '';
    const attributeOption =
      getValues(`product_details[${index}].attributes.attribute_option`) || '';
    if (!name.trim() || !attributeOption.trim()) {
      setRequiredErrorMessage('Name and attributes required');
      return;
    }

    const newAttribute: any = {
      name,
      attribute_option: attributeOption,
      display_on_product:
        getValues(`product_details[${index}].attributes.display_on_product`) ||
        false,
      is_available_for_variants:
        getValues(
          `product_details[${index}].attributes.is_available_for_variants`,
        ) || false,
    };

    const updatedAttributes = [...attributes, newAttribute];
    formSetValue(`product_details[${index}].attributes`, updatedAttributes);

    setValue(`product_details[${index}].attributes.name`, '');
    setValue(`product_details[${index}].attributes.attribute_option`, '');
    setRequiredErrorMessage('');
  };

  useEffect(() => {
    if (!Array.isArray(attributes)) {
      return;
    }

    const filteredAttributes = attributes.filter(
      (attr: any) => attr.is_available_for_variants,
    );

    const newVariants: any = filteredAttributes.flatMap((attr: any) => {
      const options = attr.attribute_option
        .split('|')
        .map((option: any) => option.trim());

      return options.map((option: any, optionIndex: any) => ({
        id: `${optionIndex}`,
        image: '',
        attribute: option,
        price: 0,
        isStock: false,
        is_available_on_product: false,
        description: '',
        variant_option: option,
      }));
    });

    formSetValue(`product_details[${index}].variant`, newVariants);
  }, [attributes, formSetValue, index]);

  const removeAttribute = (Tableindex: number) => {
    const updatedData = attributes.filter(
      (_: any, i: number) => i !== Tableindex,
    );
    setValue(`product_details[${index}].attributes`, updatedData);

    const updatedVariants = variants
      .filter
      // (variant: ProductVariant) => !variant.id.startsWith(`${index}`),
      ();
    formSetValue(`product_details[${index}].variant`, updatedVariants);
  };

  const removeVariant = (id: string) => {
    const updatedData = variants.filter(
      (item: ProductVariant) => item.id !== id,
    );
    setValue(`product_details[${index}].variant`, updatedData);
  };

  const handleAttributeChange = (
    itemIndex: number,
    field: string,
    value: any,
  ) => {
    const updatedAttributes = attributes.map((attr: any, i: number) =>
      i === itemIndex ? { ...attr, [field]: value } : attr,
    );
    formSetValue(`product_details[${index}].attributes`, updatedAttributes);
  };

  return (
    <>
      <div className="flex flex-col md:flex-row">
        <div className="mt-5 flex h-[55px] rounded-r-lg bg-light-gray md:w-1/5">
          <h1 className="items-center justify-between p-4 font-airbnb text-[16px] font-bold">
            {t('ProductListing.attributes')}
          </h1>
        </div>
        <div className="mr-5 w-full md:ml-5">
          <div className="flex flex-col md:flex-row">
            <div className="my-3 md:mb-0">
              <label
                htmlFor={t('ProductListing.name')}
                className="p-2 font-airbnb text-[14px] font-medium"
              >
                {t('ProductListing.name')} :
              </label>
              <Input
                placeholder={t('ProductListing.placeholder_name')}
                containerClassName="relative flex items-center"
                inputClassName="focus:outline-none w-full md:w-[197px] border border-gray-200 shadow-sm"
                height="38px"
                alt="name"
                {...register(`product_details[${index}].attributes.name`)}
              />
            </div>
            <div className="my-3 w-full md:mb-0 md:ml-2 md:w-[400px]">
              <label
                htmlFor={t('ProductListing.value')}
                className="p-2 font-airbnb text-[14px] font-medium"
              >
                {t('ProductListing.value')}:
              </label>
              <Input
                placeholder={t('ProductListing.placeholder_input')}
                containerClassName="relative flex items-center"
                inputClassName="focus:outline-none border border-gray-200 shadow-sm placeholder:text-[12px] w-full"
                height="38px"
                alt="values"
                aria-label="attribute_option"
                {...register(
                  `product_details[${index}].attributes.attribute_option`,
                )}
              />
              {requiredErrorMessage && (
                <p className="text-sm text-red-500">{requiredErrorMessage}</p>
              )}
            </div>
            <div className="my-2 ml-6 md:mt-8">
              <button
                type="button"
                onClick={addAttribute}
                onKeyDown={e => {
                  if (e.key === 'Enter') addAttribute();
                }}
                aria-label={t('ProductListing.add')}
                className="flex items-center justify-center rounded bg-white"
              >
                <img
                  src={AddIcon}
                  alt={t('ProductListing.add')}
                  width={40}
                  height={40}
                />
              </button>
            </div>
          </div>
          <div className="mt-4">
            {Array.isArray(attributes) && (
              <AttributesTable
                data={attributes}
                onDeleteRow={removeAttribute}
                onAttributeChange={handleAttributeChange}
              />
            )}
          </div>
        </div>
      </div>

      {/* Variant Data */}
      <div className="mt-5 w-full">
        <div className="flex h-[55px] rounded-r-lg bg-light-gray md:w-1/5">
          <h1 className="items-center justify-between p-4 font-airbnb text-[16px] font-bold">
            {t('ProductListing.variants')}
          </h1>
        </div>

        <div className="mx-5 my-10 w-full">
          {Array.isArray(variants) && (
            <VariantsTable
              variants={variants}
              register={register}
              onRemoveVariant={removeVariant}
              setValue={setValue}
              index={index}
            />
          )}
        </div>
      </div>
    </>
  );
};
